import "../css/App.css";
import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import TopBar from "../components/TopBar";

import PlantUmlEncoder from "plantuml-encoder";
import DOMPurify from "dompurify";
import ShortUniqueId from "short-unique-id";

import useLocalStorage from "../utils/LocalStorage";

function urlSafeBase64Decode(input) {
  // Revert URL-safe Base64 to standard Base64
  const base64 = input
    .replace(/-/g, "+") // Replace '-' with '+'
    .replace(/_/g, "/"); // Replace '_' with '/'

  // Decode Base64
  return atob(base64);
}

function ReceiveShare() {
  const [database, setDatabase] = useLocalStorage("database");

  const [diagramText, setDiagramText] = useState(null);
  const [diagramTitle, setDiagramTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);

  const [projectId, setProjectId] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const data = queryParams.get("d");

  const plantBaseUrl = "https://plantuml.com/plantuml/png/";

  useEffect(() => {
    const fetchData = async () => {
      if (!data) throw new Error("data parameter is required");
      let url = urlSafeBase64Decode(data);

      try {
        const response = await fetch(url, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        let santizedText = DOMPurify.sanitize(
          urlSafeBase64Decode(result.message)
        );
        if (result.title) {
          let sanitizedTitle = DOMPurify.sanitize(
            urlSafeBase64Decode(result.title)
          );
          setDiagramTitle(sanitizedTitle);
        }
        setDiagramText(santizedText);
        setImage(plantBaseUrl + PlantUmlEncoder.encode(santizedText));
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (typeof database === "undefined") {
      setDatabase({
        version: 1,
        type: "planted",
        projects: {},
      });
    }

    fetchData();
  }, [data, database, setDatabase]);

  function saveData(data, title) {
    let newProjectId = new ShortUniqueId()();
    database.projects[newProjectId] = {
      projectId: newProjectId,
      createDate: Date.now(),
      updateDate: null,
      type: "Sequence Diagram",
      revisions: [],
      name: title,
      data: PlantUmlEncoder.encode(data),
      unsavedData: PlantUmlEncoder.encode(data),
    };

    setDatabase(database);
    setProjectId(newProjectId);
  }

  return (
    <>
      <Grid container spacing={2}>
        <TopBar />
        <Grid
          container
          style={{
            marginTop: 10,
            paddingLeft: 60,
            paddingRight: 60,
          }}
        >
          {isLoading
            ? "Loading..."
            : error
            ? `Error retrieving shared diagram ${error}`
            : ""}
          {diagramText && (
            <>
              <Typography
                mt={1}
                sx={{ fontSize: 25, fontWeight: "800", color: "#909090" }}
              >
                Shared Diagram: {diagramTitle}
              </Typography>
              {image && (
                <Grid xs={12} mb={2} style={{ width: "100%" }}>
                  <img src={image} alt="Diagram" style={{ maxWidth: "100%" }} />
                </Grid>
              )}
              {diagramText && (
                <Box
                  xs={12}
                  mb={1}
                  sx={{ padding: 5, width: "90%", border: 1, borderRadius: 2 }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: diagramText.replaceAll("\n", "<BR/>"),
                    }}
                  />
                </Box>
              )}
              <Grid xs={12}>
                {projectId ? (
                  <Link to={`/project/${projectId}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    style={{ margin: 10 }}
                    onClick={() => saveData(diagramText, diagramTitle)}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ReceiveShare;
